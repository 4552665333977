import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/reset.css";
import AOS from "aos";
import "aos/dist/aos.css";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import 'amfe-flexible'
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

Vue.use(ElementUI);
Vue.prototype.axios = axios;
Vue.config.productionTip = false;

new Vue({
  created() {
    AOS.init();
  },
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
