import axios from 'axios'

const service = axios.create({
  baseURL: 'https://pc.baichuanyc.com/prod-api',
  // baseURL: 'http://192.168.1.114:8061/',
  // baseURL: 'http://60.216.106.131:38080/prod-api/',
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 6 * 1000
})

// 2.请求拦截器



export default service
