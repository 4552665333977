import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import GetOffer from "../views/GetOffer.vue";
import AboutMe from "../views/AboutMe.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/GetOffer",
    name: "GetOffer",
    component: GetOffer,
  },
  {
    path: "/AboutMe",
    name: "AboutMe",
    component: AboutMe,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
