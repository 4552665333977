// 导入封装好的axios实例
import request from "../utils/request.js";

// 根据 首页 获取  轮播图
export const getSwiter = (data) => {
  return request({
    url: "/api/getBannerList",
    method: "POST",
    data,
  });
};
// 服务项目列表
export const getServer = (data) => {
  return request({
    url: "/api/getServiceProjectList",
    method: "POST",
    data,
  });
};
// 资源保障
export const getZiyuan = (data) => {
  return request({
    url: "/api/getResourceGuaranteeList",
    method: "POST",
    data,
  });
};
// 产品价值
export const getJia = (data) => {
  return request({
    url: "/api/getProductValueList",
    method: "POST",
    data,
  });
};

// 服务优势
export const getYou = (data) => {
  return request({
    url: "/api/getServiceAdvantageList",
    method: "POST",
    data,
  });
};
// 托运车型列表
export const getTuo = (data) => {
  return request({
    url: "/api/getCarList",
    method: "POST",
    data,
  });
};
// 报价
export const getBao = (data) => {
  return request({
    url: "/api/insertOffer",
    method: "POST",
    data,
  });
};
// 关于我们
export const getAbout = (data) => {
  return request({
    url: "/api/getWe",
    method: "POST",
    data,
  });
};

