<template>
  <div>
    <div class="recom_all">
      <div class="logo_top">
        <img src="@/assets/logo.png" alt="" />
      </div>
      <div class="all_rem">
        <div
          @mouseenter="clicken(index)"
          @mouseleave="clickdown"
          @click="clickinv(index)"
          :class="['alonrem', inv == index ? 'active' : '']"
          v-for="(item, index) in items"
          :key="index"
        >
          {{ item }}
          <span
            class="spananiimaion"
            v-show="inv == index && cinv != null"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["msg"],
  data() {
    return {
      items: ["首页", "获取报价", "关于我们"],
      inv: 0,
      cinv: null,
    };
  },
  created() {
    this.inv = this.msg;
  },
  methods: {
    clicken(index) {
      this.inv = index;
      this.cinv = index;
    },
    clickdown() {
      this.inv = this.msg;
      this.cinv = null;
    },
    clickinv(index) {
      if (index == 0) {
        this.$router.push('/')
      } else if (index == 1) {
        this.$router.push('/GetOffer')
      } else if (index == 2) {
        this.$router.push('/AboutMe')
      }
    },
  },
};
</script>

<style>
.recom_all {
  width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 30px;
  padding: 32px 0 27px;
}
.logo_top {
  width: 30%;
  display: flex;
  justify-content: center;
}
.logo_top img {
  width: 213px;
  height: 32px;
}
.all_rem {
  width: 70%;
  display: flex;
  justify-content: space-around;
}
.alonrem {
  color: #000000;
  font-size: 20px;
  width: 100px;
  text-align: center;
  cursor: pointer;
}
.alonrem span {
  height: 3px;
  background: #0565fd;
  display: block;
  margin: 5px auto;
  width: 0; /* 初始宽度为0 */
  animation: growRect 0.3s ease forwards; /* 使用ease-in-out缓动函数 */
}
.active {
  color: #0565fd;
  font-weight: bold;
}

@keyframes growRect {
  0% {
    width: 0; /* 动画开始时宽度为0 */
  }
  100% {
    width: 60px; /* 动画结束时宽度为100px */
  }
}
</style>