<template>
  <div class="page">
    <top-word :msg="2"></top-word>
    <div class="metextall">
      <img src="@/assets/back.png" alt="" />
      <div class="metextall_text">
        <div>{{datalist.title}}</div>
        <div>{{datalist.describe}}</div>
      </div>
    </div>
    <div class="compamyinfor">
      <div
        :class="['aloneinfor', inv == index ? 'active' : '']"
        @click="clickinv(index)"
        @mouseenter="clickenter(index)"
        @mouseleave="clickleave"
        v-for="(item, index) in items"
        :key="index"
      >
        <img :src="inv == index ? itemspic[inv] : item.image" alt="" />{{
          item.name
        }}
      </div>
    </div>
    <div class="text_html">
      <div class="alltext" v-html="datalist.content" v-if="cinv == 0"></div>
      <div class="alltext" v-html="datalist.content1" v-else></div>
    </div>
    <footer-word></footer-word>
    <fixed-word></fixed-word>
  </div>
</template>

<script>
import { getAbout } from "../utils/api";
import FixedWord from "@/components/FixedWord.vue";
import TopWord from "@/components/TopWord.vue";
import FooterWord from "@/components/FooterWord.vue";

import jiaone from "@/assets/ee.png";
import jiatwo from "@/assets/ww.png";
import jiathree from "@/assets/rr.png";
import jiatfour from "@/assets/qq.png";
export default {
  components: {
    FooterWord,
    TopWord,
    FixedWord,
  },
  data() {
    return {
      items: [
        { name: "公司介绍", image: jiaone },
        { name: "联系我们", image: jiatwo },
      ],
      itemspic: [jiathree, jiatfour],
      inv: 0,
      cinv: 0,
      datalist: {},
    };
  },
  created() {
    this.getAbout();
    if(JSON.parse(this.$route.query.id)){
      this.inv = JSON.parse(this.$route.query.id);
      this.cinv = this.inv;
    }
  },
  methods: {
    getAbout() {
      getAbout().then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          this.datalist = res.data.data;
        }
      });
    },
    clickinv(index) {
      this.inv = index;
      this.cinv = this.inv;
    },
    clickenter(index) {
      this.inv = index;
    },
    clickleave() {
      this.inv = this.cinv;
    },
  },
};
</script>

<style scoped>
.page {
  width: 1920px;
  background: #fff;
  margin: 0 auto;
}
.metextall {
  width: 100%;
  position: relative;
  height: 267px;
}
.metextall img {
  width: 100%;
  height: 267px;
  background: #ccc;
  position: absolute;
  z-index: 1;
}
.metextall_text {
  position: absolute;
  z-index: 8;
  width: 100%;
}
.metextall_text > div:nth-child(1) {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  padding-top: 82px;
}
.metextall_text > div:nth-child(2) {
  font-size: 20px;
  color: #fff;
  margin-top: 24px;
  text-align: center;
}
.compamyinfor {
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 18px 0;
}
.aloneinfor {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 20px;
  cursor: pointer;
}
.aloneinfor img {
  width: 19px;
  height: 19px;
  margin-right: 10px;
}
.active {
  color: #0565fd;
}
.text_html {
  width: 100%;
  background: #f5f9fd;
  padding: 20px;
  min-height: 600px;
}
.alltext{
  font-size: 18px;
}
</style>