<template>
  <div class="page">
    <!-- <div class="textfonst">111</div> -->
    <top-word :msg="0"></top-word>
    <div class="blockswpter">
      <el-carousel trigger="click" class="my-carousel-heigh">
        <el-carousel-item
          v-for="(item, index) in picList"
          :key="index"
          class="my-carousel-heigh"
        >
          <img class="imagswp" :src="item.image" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="servertext" v-if="serverlist.length">
      <div data-aos="fade-up">服务项目</div>
      <div data-aos="slide-down">SERVICE ITEMS</div>
    </div>
    <div class="bao_all">
      <div
        class="alone_bao animation-fade"
        v-for="(item, index) in serverlist"
        :key="index"
        @click="clickbao"
      >
        <img class="imagebao" :src="item.image" alt="" />
        <div>{{ item.title }}</div>
        <div>
          {{ item.describe }}
          <!-- <p>赠送</p> -->
        </div>
        <div>获取报价</div>
      </div>
    </div>
    <div class="ziyuan" v-if="zinumList.title">
      <img src="@/assets/ll.png" alt="" class="picone" />
      <div class="textall">
        <div class="ziyuan_title" data-aos="fade-up">{{ zinumList.title }}</div>
        <div class="ziyuan_text" data-aos="slide-down">
          {{ zinumList.describe }}
        </div>
        <div class="ziall_cards">
          <div
            class="alonez0_ards"
            data-aos="fade-up"
            v-for="(item, index) in zilist"
            :key="index"
          >
            <div><img :src="item.image" alt="" /> {{ item.name }}</div>
            <div v-if="index == 0">
              <animate-number
                from="1"
                :to="Number(zinumList.enterprise_cnt)"
                :key="entryRod"
              ></animate-number
              >+
            </div>
            <div v-if="index == 1">
              <animate-number
                from="1"
                :to="Number(zinumList.resource_cnt)"
                :key="entryRod"
              ></animate-number
              >+
            </div>
            <div v-if="index == 2">
              <animate-number
                from="1"
                :to="Number(zinumList.line_cnt)"
                :key="entryRod"
              ></animate-number
              >+
            </div>
            <div v-if="index == 3">
              <animate-number
                from="1"
                :to="Number(zinumList.city_cnt)"
                :key="entryRod"
              ></animate-number
              >+
            </div>
            <div v-if="index == 4">
              <animate-number
                from="1"
                :to="Number(zinumList.warehouse_cnt)"
                :key="entryRod"
              ></animate-number
              >+
            </div>
            <div v-if="index == 5">
              <animate-number
                from="1"
                :to="Number(zinumList.place_cnt)"
                :key="entryRod"
              ></animate-number
              >+
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tuo_liu" v-if="yulist.length">
      <div class="tuo_liu_title" data-aos="fade-up">托运流程</div>
      <div class="tuo_liu_text" data-aos="slide-down">SHIPPING PROCESS</div>
      <div class="alltuo">
        <div
          class="alone_tuo"
          data-aos="fade-down"
          v-for="(item, index) in yulist"
          :key="index"
        >
          <img :src="item.image" alt="" />
          <div>{{ item.name }}</div>
          <div>{{ item.text }}</div>
        </div>
      </div>
      <div class="numall">
        <div
          class="numalone"
          data-aos="zoom-in"
          v-for="(item, index) in 6"
          :key="index"
        >
          {{ index + 1 }}
        </div>
        <div class="numalonespan"></div>
      </div>
    </div>
    <div class="producta_all" v-if="jzhilist.length">
      <div class="producta_all_title" data-aos="fade-up">产品价值</div>
      <div class="producta_all_text" data-aos="slide-down">PRODUCT VALUE</div>
      <div class="producta_all_right">
        <div
          class="aloneprodyct"
          data-aos="fade-up"
          v-for="(item, index) in jzhilist"
          :key="index"
        >
          <img :src="item.image" alt="" />
          <div class="aloneprodyct_text">
            <div>{{ item.title }}</div>
            <div>{{ item.describe }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="seryouall" v-if="youShlist.length">
      <div class="aloneser_title" data-aos="fade-up">服务优势</div>
      <div class="aloneser_text" data-aos="slide-down">SERVICE ADVANTAGE</div>
      <div class="cards_one">
        <div
          class="alonecards animation-fade"
          v-for="(item, index) in youShlist"
          :key="index"
        >
          <img :src="item.image" alt="" />
          <div>0{{ index + 1 }}</div>
          <div>{{ item.title }}</div>
          <div>{{ item.describe }}</div>
        </div>
      </div>
    </div>
    <footer-word></footer-word>
    <fixed-word></fixed-word>
    <!-- <transition name="fade">
      <div v-if="show" class="">淡入效果的内容</div>
    </transition> -->
  </div>
</template>

<script>
import { getSwiter, getServer, getZiyuan, getJia, getYou } from "../utils/api";
import FixedWord from "@/components/FixedWord.vue";
import TopWord from "@/components/TopWord.vue";
import FooterWord from "@/components/FooterWord.vue";
import imageone from "@/assets/q.png";
import imagetwo from "@/assets/e.png";
import imagethree from "@/assets/y.png";
import imagefour from "@/assets/w.png";
// import imagefive from "@/assets/r.png";
// import imageseven from "@/assets/u.png";

import twoone from "@/assets/z.png";
import twotwo from "@/assets/x.png";
import twothree from "@/assets/c.png";
import twothfour from "@/assets/v.png";
import twothfive from "@/assets/v-1.png";
import twothsix from "@/assets/n.png";

import jiaone from "@/assets/ff.png";
import jiatwo from "@/assets/gg.png";
import jiathree from "@/assets/hh.png";
import jiatfour from "@/assets/kk.png";
export default {
  components: {
    FooterWord,
    TopWord,
    FixedWord,
  },
  data() {
    return {
      picList: [],
      entryRod: "500",
      rodNumber: null,
      serverlist: [],
      jzhilist: [],
      youShlist: [],
      zilist: [
        { name: "合作物流企业", image: imageone },
        { name: "可调配运力资源", image: imagetwo },
        { name: "国内运输专线", image: imagethree },
        { name: "覆盖行政县域", image: imagefour },
        // { name: "车辆仓配中心", image: imagefive },
        // { name: "仓储车位数", image: imageseven },
      ],
      yulist: [
        { name: "预约交车", text: "提供时间地点", image: twoone },
        { name: "检查车辆", text: "车辆外观内饰检查", image: twotwo },
        { name: "签订协议", text: "运输协议赠送保险", image: twothree },
        { name: "安全运输", text: "优质运力全程保险", image: twothfour },
        { name: "接车验收", text: "检车无误联系接车", image: twothfive },
        { name: "支付运费", text: "确认无误付尾款", image: twothsix },
      ],
      jiallist: [
        {
          name: "运输价格透明合理",
          text: "提送车、保险、服务够费用一目了然",
          image: jiaone,
        },
        {
          name: "运力资源全网匹配",
          text: "经过严格审核准入，整合了数千家优质承运商，建立了覆盖全国的运力网络",
          image: jiatwo,
        },
        {
          name: "在途车辆全程可控",
          text: "24小时GPS定位跟踪，上门提车、在途运输、送车道门，全流程行驶轨迹可视化",
          image: jiathree,
        },
        {
          name: "平台服务便捷高效",
          text: "根据实际业务长江，按角色分版本设计功能，从接单到交付的每个环节，通过手机+电脑皆可完成",
          image: jiatfour,
        },
      ],
      youlist: [
        { name: "多年托运经验", text: "品牌源于2008年专注轿车托运" },
        {
          name: "客服一对一服务",
          text: "一对一的管家式贴心服务实时解答运车疑问",
        },
        { name: "上门提送", text: "上门提车，送车上门省时、省心、省事！" },
        { name: "全程保险", text: "与太平洋保险公司合作车辆安全有保障" },
        { name: "全国网点覆盖", text: "运输干线网络辐射全国业务辐射全国各地" },
      ],
      zinumList: {},
      widthScreen: "",
      heightWith: 615,
    };
  },

  created() {
    this.getpic();
    this.getServer();
    this.getZiyuan();
    this.getJia();
    this.getYou();
    setTimeout(() => {
      this.rodNumber = this.entryRod++;
    }, 1000);
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      duration: 3000,
    });
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 跳转报价
    clickbao() {
      this.$router.push("/GetOffer");
    },
    // 首页轮播
    getpic() {
      getSwiter({ type: 1 }).then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          this.picList = res.data.data;
        }
      });
    },
    // 服务项目
    getServer() {
      getServer().then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          if (res.data.data.length) {
            this.serverlist = res.data.data;
          }
        }
      });
    },
    // 资源保障
    getZiyuan() {
      getZiyuan({}).then((res) => {
        // console.log(res, "把膨胀");
        if (res.data.code == 200) {
          if (res.data.data.length) {
            this.zinumList = res.data.data[0];
          }
        }
      });
    },
    //产品价值
    getJia() {
      getJia({}).then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          if (res.data.data.length) {
            this.jzhilist = res.data.data;
          }
        }
      });
    },
    //服务优势
    getYou() {
      getYou({}).then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          if (res.data.data.length) {
            this.youShlist = res.data.data;
          }
        }
      });
    },

    handleScroll() {
      // console.log(window.scrollY);
      if (window.scrollY > 500 && window.scrollY < 1200) {
        this.rodNumber = this.entryRod++;
      } else {
        // this.show = false;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.page {
  /* background: #e7f0fb; */
  background: #fff;
  margin: 0 auto;
  width: 1920px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.recom_all {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 30px;
  padding: 32px 0 27px;
}
.logo_top {
  width: 30%;
  display: flex;
  justify-content: center;
}
.logo_top img {
  width: 213px;
  height: 32px;
}
.all_rem {
  width: 70%;
  display: flex;
  justify-content: space-around;
}
.alonrem {
  color: #000000;
  font-size: 20px;
  width: 100px;
  text-align: center;
  cursor: pointer;
}
.alonrem span {
  height: 3px;
  background: #0565fd;
  display: block;
  margin: 5px auto;
  width: 0; /* 初始宽度为0 */
  animation: growRect 0.3s ease forwards; /* 使用ease-in-out缓动函数 */
}
.active {
  color: #0565fd;
  font-weight: bold;
}

@keyframes growRect {
  0% {
    width: 0; /* 动画开始时宽度为0 */
  }
  100% {
    width: 60px; /* 动画结束时宽度为100px */
  }
}
.blockswpter {
  width: 1920px;
  height: 615px;
}
.imagswp {
  width: 100%;
  height: 615px !important;
}
.servertext {
  text-align: center;
  color: #000000;
  font-size: 30px;
  font-weight: bold;
  padding: 30px 0;
}
.servertext > div:last-child {
  font-size: 20px;
  font-weight: initial;
  margin-top: 8px;
}
.bao_all {
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.alone_bao {
  width: 352px;
  background: #fff;
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.alone_bao:hover {
  transform: translateY(-20px);
  transition: all 0.3s ease-in-out;
}
/* .alone-introduce img:hover {
  
} */
.imagebao {
  width: 352px;
  height: 299px;
  background: #ccc;
}
.alone_bao > div:nth-child(2) {
  color: #000000;
  font-size: 18px;
  font-weight: bold;
  padding-top: 14px;
  padding-bottom: 17px;
  text-align: center;
}
.alone_bao > div:nth-child(3) {
  width: 232px;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #dedede;
  font-size: 15px;
  color: #000000;
  text-align: center;
  padding: 9px 40px;
  margin: 0 auto;
  border-radius: 8px;
  position: relative;
}
.alone_bao > div:nth-child(3) span {
  color: #e50000;
  font-size: 22px;
}
.alone_bao p {
  font-size: 12px;
  color: #fff;
  background: #fb622a;
  text-align: center;
  padding: 2px 7px;
  width: 38px;
  position: absolute;
  right: 0;
  top: -10px;
  border-radius: 5px;
}
.alone_bao > div:last-child {
  width: 238px;
  background: linear-gradient(180deg, #019ffd 0%, #0565fd 100%);
  font-size: 16px;
  color: #fff;
  padding: 13px 0;
  margin: 14px auto 20px;
  text-align: center;
}
.ziyuan {
  width: 100%;
  margin: 0px auto;
  padding-top: 20px;
  position: relative;
  height: 650px;
}
.picone {
  width: 100%;
  height: 649px;
  position: absolute;
  z-index: 1;
}
.textall {
  position: absolute;
  z-index: 9;
  width: 100%;
}
.ziyuan_title {
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  margin-top: 49px;
  margin-bottom: 14px;
  text-align: center;
}
.ziyuan_text {
  font-size: 20px;
  color: #fff;
  text-align: center;
}
.ziall_cards {
  display: grid;
  grid-template-columns: repeat(2, 534px);
  grid-gap: 40px 0;
  width: 85%;
  margin: 40px auto;
  justify-content: space-around;
  /* background: aqua; */
}
.alonez0_ards {
  width: 95%;
  background: rgba(255, 255, 255, 0.18);
  padding: 42px 20px 41px 58px;
  border-radius: 20px;
}
.alonez0_ards > div:nth-child(1) {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 20px;
}
.alonez0_ards > div:nth-child(1) img {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}
.alonez0_ards > div:last-child {
  font-size: 40px;
  color: #fff;
  font-weight: bold;
  margin-top: 30px;
}
.tuo_liu {
  width: 100%;
  background-color: #fff;
  padding-top: 20px;
}
.tuo_liu_title {
  color: #000;
  font-size: 30px;
  font-weight: bold;
  margin-top: 46px;
  text-align: center;
  margin-bottom: 8px;
}
.tuo_liu_text {
  text-align: center;
  font-size: 20px;
}
.alltuo {
  width: 90%;
  display: flex;
  justify-content: space-around;
  margin: 60px auto 0;
}
.alone_tuo {
  width: 150px;
  text-align: center;
}
.alone_tuo img {
  width: 60px;
  height: 60px;
  display: block;
  margin: 0 auto;
}
.alone_tuo > div:nth-child(2) {
  color: #000;
  font-size: 22px;
  margin-top: 40px;
}
.alone_tuo > div:nth-child(3) {
  color: #333;
  font-size: 18px;
  margin-top: 14px;
}
.numall {
  width: 90%;
  margin: 30px auto 0;
  padding-bottom: 44px;
  display: flex;
  justify-content: space-around;
  position: relative;
}
.numalone {
  width: 150px;
  color: #666;
  font-size: 25px;
  width: 52px;
  height: 52px;
  text-align: center;
  line-height: 52px;
  border: 1px solid #666;
  border-radius: 50%;
  background: #fff;
  position: relative;
  z-index: 999;
}
.numalonespan {
  width: 80%;
  height: 30px;
  border-bottom: 1px dashed #5e5e5e;
  position: absolute;
  z-index: 1;
}
.producta_all {
  width: 100%;
  background: #fff;
}
.producta_all_title {
  color: #000;
  font-size: 30px;
  font-weight: bold;
  padding-top: 40px;
  text-align: center;
}
.producta_all_text {
  text-align: center;
  margin-top: 14px;
}
.producta_all_right {
  display: grid;
  grid-template-columns: repeat(2, 477px);
  grid-gap: 96px 0;
  width: 90%;
  justify-content: space-around;
  margin-top: 44px;
  padding-bottom: 40px;
}
.aloneprodyct {
  width: 600px;
  display: flex;
  align-items: center;
  /* background: aqua */
}
.aloneprodyct img {
  width: 70px;
  height: 70px;
}
.aloneprodyct_text {
  flex: 1;
  margin-left: 20px;
}
.aloneprodyct_text > div:nth-child(1) {
  color: #000;
  font-weight: bold;
  font-size: 24px;
}
.aloneprodyct_text > div:nth-child(2) {
  color: #000;
  font-size: 18px;
  margin-top: 16px;
}
.seryouall {
  width: 100%;
  background: #eff6fd;
}
.aloneser_title {
  text-align: center;
  color: #000;
  font-size: 40px;
  font-weight: bold;
  padding-top: 44px;
}
.aloneser_text {
  font-size: 20px;
  margin-top: 14px;
  text-align: center;
}
.cards_one {
  display: flex;
  /* grid-template-columns: repeat(5, 279px); */
  width: 90%;
  justify-content: space-around;
  margin: 36px auto 0;
  padding-bottom: 50px;
}
.alonecards {
  width: 279px;
  background: #ffffff;
  text-align: center;
  padding-bottom: 20px;
  cursor: pointer;
}
.alonecards:hover {
  transform: translateY(-20px);
  transition: all 0.3s ease-in-out;
}
.alonecards img {
  width: 279px;
  height: 227px;
  background: #ccc;
}
.alonecards > div:nth-child(2) {
  width: 56px;
  background: #0565fd;
  color: #fff;
  font-size: 24px;
  height: 56px;
  line-height: 56px;
  border-radius: 50%;
  margin: -28px auto 0;
  position: relative;
  z-index: 99;
}
.alonecards > div:nth-child(3) {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin-top: 20px;
}
.alonecards > div:nth-child(4) {
  font-size: 16px;
  color: #666;
  width: 180px;
  margin: 10px auto 0;
}
.my-carousel-heigh {
  height: 615px !important;
}
/deep/ .el-carousel__arrow {
  width: 0px !important;
  height: 0px !important;
  border: none !important;
  outline: 0 !important;
  border-radius: 0 !important;
  position: relative;
  z-index: -999;
}
</style>
