<template>
  <div class="righcards">
    <div class="right_one_all" v-if="oneshow">
      <img src="@/assets/hhsc.png" alt="" />服务热线 {{ mainlist.phone }}
    </div>
    <div class="aloneright" @mouseenter="oneenter" @mouseleave="oneleave">
      <img src="@/assets/dh_fill.png" v-if="oneshow" alt="" />
      <img src="@/assets/dh.png" v-else alt="" />
      服务热线
    </div>
    <div
      class="aloneright"
      @mouseenter="tworenter"
      @mouseleave="twoleave"
      @click="clickme"
    >
      <img src="@/assets/hd_fill.png" alt="" v-if="twoshow" />
      <img src="@/assets/hd.png" v-else alt="" />
      联系我们
    </div>
    <div
      class="aloneright"
      @mouseenter="threeenter"
      @mouseleave="threeleave"
      @click="clickcar"
    >
      <img src="@/assets/hc_fill.png" alt="" v-if="threeshow" />
      <img src="@/assets/hc.png" alt="" v-else />
      我要运车
    </div>
    <div class="right_four_all" v-if="fourshow">
      <img :src="mainlist.qrcode" alt="" />微信公众号
    </div>
    <div class="aloneright" @mouseenter="fourenter" @mouseleave="fourleave">
      <img src="@/assets/gz_fill.png" alt="" v-if="fourshow" />
      <img src="@/assets/gz.png" v-else alt="" />
      扫码关注
    </div>
  </div>
</template>

<script>
import { getAbout } from "../utils/api";
export default {
  data() {
    return {
      oneshow: false,
      fourshow: false,
      twoshow: false,
      threeshow: false,
      mainlist: {},
    };
  },
  created() {
    this.getAbout();
  },
  methods: {
    //系统配置
    getAbout() {
      getAbout().then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          this.mainlist = res.data.data;
        }
      });
    },
    oneenter() {
      this.oneshow = true;
    },
    oneleave() {
      this.oneshow = false;
    },
    fourenter() {
      this.fourshow = true;
    },
    fourleave() {
      this.fourshow = false;
    },
    tworenter() {
      this.twoshow = true;
    },
    twoleave() {
      this.twoshow = false;
    },
    threeenter() {
      this.threeshow = true;
    },
    threeleave() {
      this.threeshow = false;
    },
    clickcar() {
      const routeData = this.$router.resolve({
        path: "/GetOffer",
        query: {},
      });
      window.open(routeData.href, "_blank");
    },
    clickme() {
      const routeData = this.$router.resolve({
        path: "/AboutMe",
        query: {id: JSON.stringify(1)},
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style>
.righcards {
  position: fixed;
  width: 76px;
  background: #fff;
  right: 20px;
  top: 395px;
  z-index: 999;
  padding: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.aloneright {
  color: #000;
  font-size: 14px;
  text-align: center;
  padding-top: 6px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  cursor: pointer;
}
.aloneright img {
  width: 28px;
  height: 28px;
  display: block;
  margin: 0px auto 10px;
  padding-top: 10px;
}
.aloneright:last-child {
  border: none;
}
.right_one_all {
  position: absolute;
  top: 0;
  width: 323px;
  background: #fff;
  display: flex;
  align-items: center;
  right: 96px;
  padding: 36px 22px;
  color: #000;
  font-size: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.right_one_all img {
  width: 27px;
  height: 24px;
  margin-right: 10px;
}
.aloneright:hover {
  color: #0565fd;
}
.right_four_all {
  background: #fff;
  position: absolute;
  bottom: 0;
  color: #000;
  font-size: 10px;
  right: 96px;
  padding: 8px 8px;
  text-align: center;
}
.right_four_all img {
  width: 70px;
  height: 70px;

  background: #ccc;
  display: block;
  margin: 0 auto;
}
</style>