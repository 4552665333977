<template>
  <div class="page">
    <top-word :msg="1"></top-word>
    <div class="yun_all">
      <img src="@/assets/ll.png" alt="" class="picone" />
      <div class="allyunadd" v-if="showpop == false">
        <div class="numall">
          <div
            class="numalone"
            data-aos="zoom-in"
            v-for="(item, index) in 4"
            :key="index"
          >
            {{ index + 1 }}
          </div>
          <div class="numalonespan"></div>
        </div>
        <div class="textyunalne">
          <div class="aloneyun" v-for="(item, index) in items" :key="index">
            <div>{{ item.name }}</div>
            <div>{{ item.text }}</div>
          </div>
        </div>
        <div class="right_pic" @click="rightclick">
          <img src="@/assets/ri.png" alt="" />
        </div>
      </div>
      <div class="allyunadd" v-if="showpop">
        <div class="numall">
          <div
            class="numalone"
            data-aos="zoom-in"
            v-for="(item, index) in 4"
            :key="index"
          >
            {{ index + 5 }}
          </div>
          <div class="numalonespan"></div>
        </div>
        <div class="textyunalne">
          <div class="aloneyun" v-for="(item, index) in twoitems" :key="index">
            <div>{{ item.name }}</div>
            <div>{{ item.text }}</div>
          </div>
        </div>
        <div class="right_pic wtoright_pic" @click="rightclick">
          <img src="@/assets/a.png" alt="" />
        </div>
      </div>
    </div>
    <div class="carall">
      <div class="car_left">
        <div class="car_left_title">15秒免费获取运车报价</div>
        <div class="car_left_text">
          今天已有 <span>100+</span> 位用户获取了运车预算
        </div>
        <div class="car_left_cards">
          <div class="car_left_cards_one">托运车型<span>*</span></div>
          <div class="car_left_cardstwo">
            <el-select v-model="carxing" placeholder="请选择车型">
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="car_left_cards">
          <div class="car_left_cards_one">始发地<span>*</span></div>
          <div class="car_left_cardstwo">
            <div id="app">
              <el-cascader
                size="large"
                :options="optionstwo"
                v-model="selectedOptions"
                @change="addressChange"
                placeholder="请选择始发城市"
              >
              </el-cascader>
            </div>
          </div>
        </div>
        <div class="car_left_cards">
          <div class="car_left_cards_one">目的地<span>*</span></div>
          <div class="car_left_cardstwo">
            <div id="app">
              <el-cascader
                size="large"
                :options="optionstwo"
                v-model="twoselectedOptions"
                @change="twoaddressChange"
                placeholder="请选择到达城市"
              >
              </el-cascader>
            </div>
          </div>
        </div>
        <div class="car_left_cards">
          <div class="car_left_cards_one">手机号码<span>*</span></div>
          <div class="car_left_cardstwo">
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
        </div>
        <div class="senmioa" @click="clicksubmit">15秒获取报价</div>
      </div>
      <div class="center_line"></div>
      <div class="text_right">
        <div class="text_right_topback">
          <img src="@/assets/bat.png" alt="" />
          <p>客服专线</p>
        </div>
        <div class="quan_text">
          <div><span></span> 全国服务热线：</div>
          <div>{{ mainlist.phone }} <span>(08:00~22:00)</span></div>
        </div>
        <div class="quan_texttwo">
          <div><span></span> 微信咨询</div>
          <div class="allimg">
            <div class="wximg">
              <img :src="mainlist.qrcode" alt="" />添加客服微信
            </div>
            <div class="wximg">
              <img :src="mainlist.qrcode1" alt="" />微信公众号
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="baicarinfor">
      <div class="bai_one">
        <span>百川运车</span>
        <p>-</p>
        一家专业运车的公司
      </div>
      <div class="bai_two">
        <div class="alone_bai">
          源于
          <p>2009 <span>年</span></p>
        </div>
        <div class="alone_bai">
          安全运输
          <p>15 <span>年</span></p>
        </div>
        <div class="alone_bai">
          中国服务城市
          <p>300 <span>+</span></p>
        </div>
      </div>
      <div class="bai_three">
        <div class="alone_three">
          <img src="@/assets/sdg.png" alt="" />
          <div class="alone_three_text">
            <div>上门提送</div>
            <div>上门提车，送车上门</div>
          </div>
        </div>
        <div class="alone_three">
          <img src="@/assets/srtherj.png" alt="" />
          <div class="alone_three_text">
            <div>托运满意再付费</div>
            <div>先托运，满意后再付款</div>
          </div>
        </div>
        <div class="alone_three">
          <img src="@/assets/ykl.png" alt="" />
          <div class="alone_three_text">
            <div>一对一客服</div>
            <div>一对一管家式客服服务</div>
          </div>
        </div>
        <div class="alone_three">
          <img src="@/assets/qaweq.png" alt="" />
          <div class="alone_three_text">
            <div>全国网点覆盖</div>
            <div>运输干线网络全国覆盖</div>
          </div>
        </div>
        <div class="alone_three">
          <img src="@/assets/zxcv z.png" alt="" />
          <div class="alone_three_text">
            <div>全程保险</div>
            <div>与太平洋保险合作</div>
          </div>
        </div>
      </div>
    </div>
    <footer-word></footer-word>
    <fixed-word></fixed-word>
  </div>
</template>

<script>
import { getTuo, getBao, getAbout } from "../utils/api";
import { regionData, codeToText } from "element-china-area-data";
import FixedWord from "@/components/FixedWord.vue";
import TopWord from "@/components/TopWord.vue";
import FooterWord from "@/components/FooterWord.vue";
export default {
  data() {
    return {
      optionstwo: regionData,
      selectedOptions: [],
      twoselectedOptions: [],
      startaddress: "",
      endaddress: "",
      options: [],
      value: "",
      carxing: "",
      input: "",
      showpop: false,
      mainlist: {},
      widthScreen: "",
      items: [
        {
          name: "填写运单",
          text: "微信搜索 '百川运车小程序' ",
        },
        {
          name: "接受报价",
          text: "根据承运人报价信息，选择合适的价格进行订单确认",
        },
        {
          name: "支付预付款",
          text: "完善个人及车辆信息并支付预付款",
        },
        {
          name: "验车交车",
          text: "按照预约时间将轿车送至物流网点进行车辆检查",
        },
      ],
      twoitems: [
        {
          name: "装板发运",
          text: "司机师傅会将车辆安全装载至专业运输轿车的板车上，启程运输",
        },
        {
          name: "在途查询",
          text: "登录百川运车微信小程序进入订单详情页查看",
        },
        {
          name: "安全送达",
          text: "全程保险护航爱车安全到达目的地",
        },
        {
          name: "支付尾款取车",
          text: "收车人凭身份证进行验车，无误后支付尾款取车",
        },
      ],
    };
  },
  components: {
    FooterWord,
    TopWord,
    FixedWord,
  },
  created() {
    this.getTuo();
    this.getAbout();
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      duration:3000
    });
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    //系统配置
    getAbout() {
      getAbout().then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          this.mainlist = res.data.data;
        }
      });
    },
    //获取报价
    clicksubmit() {
      var ph =
        /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (this.carxing == "") {
        this.$message.error("请选择车型");
        return;
      }
      if (this.carxing == "") {
        this.$message.error("请选择车型");
        return;
      }
      if (this.startaddress == "") {
        this.$message.error("请选择始发城市地址");
        return;
      }
      if (this.endaddress == "") {
        this.$message.error("请选择到达城市地址");
        return;
      }
      if (!ph.test(this.input)) {
        this.$message.error("请输入正确格式的手机号");
        return;
      }
      getBao({
        car: this.carxing,
        startAddr: this.startaddress,
        endAddr: this.endaddress,
        phone: this.input,
      }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getTuo() {
      getTuo().then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          res.data.data.forEach((item) => {
            this.options.push({ value: item.id, label: item.name });
          });
        }
      });
    },
    rightclick() {
      this.showpop = !this.showpop;
    },
    addressChange(arr) {
      this.startaddress =
        codeToText[arr[0]] +
        "-" +
        codeToText[arr[1]] +
        "-" +
        codeToText[arr[2]];
    },
    twoaddressChange(arr) {
      this.endaddress =
        codeToText[arr[0]] +
        "-" +
        codeToText[arr[1]] +
        "-" +
        codeToText[arr[2]];
    },
  },
};
</script>

<style scoped>
.page {
  background: #fff;
  margin: 0 auto;
}
.yun_all {
  width: 100%;
  height: 266px;
  position: relative;
}
.picone {
  width: 100%;
  height: 266px;
  position: absolute;
  z-index: 1;
}
.allyunadd {
  position: absolute;
  z-index: 8;
  width: 100%;
}
.numall {
  width: 80%;
  margin: 52px auto 0;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-around;
  position: relative;
}
.numalone {
  width: 150px;
  color: #fff;
  font-size: 25px;
  width: 52px;
  height: 52px;
  text-align: center;
  line-height: 52px;
  border: 1px solid #fff;
  border-radius: 50%;
  background: rgba(1, 152, 253, 0.9);
  position: relative;
  z-index: 999;
}
.numalonespan {
  width: 70%;
  height: 30px;
  border-bottom: 1px dashed #fff;
  position: absolute;
  z-index: 1;
}
.textyunalne {
  width: 80%;
  display: flex;
  justify-content: space-around;
  color: #fff;
  margin: 0 auto;
  text-align: center;
}
.aloneyun > div:nth-child(1) {
  font-size: 22px;
  font-weight: bold;
}
.aloneyun > div:nth-child(2) {
  font-size: 16px;
  margin-top: 16px;
  width: 266px;
}
.right_pic {
  width: 53px;
  /* padding: 13px 0; */
  /* background: rgba(255, 255, 255, 0.3); */
  color: #fff;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 105px;
  right: 157px;
}
.right_pic img {
  width: 53px;
  height: 44px;
}
.wtoright_pic {
  top: 105px;
  left: 157px;
}
.carall {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: 66px auto;
  align-items: center;
}
.car_left {
  width: 675px;
  text-align: center;
}
.car_left_title {
  color: #000;
  font-size: 30px;
  font-weight: bold;
}
.car_left_text {
  color: #666;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 50px;
}
.car_left_text span {
  color: #0565fd;
}
.car_left_cards {
  margin-top: 30px;
  display: flex;
  align-items: center;
  width: 100%;
}
.car_left_cards_one {
  color: #000;
  font-size: 18px;
  font-weight: bold;
  width: 100px;
  /* background: aqua; */
}
.car_left_cards_one span {
  color: #eb0303;
}
.car_left_cardstwo {
  flex: 1;
  margin-left: 20px;
}
.el-select {
  width: 545px;
}

/deep/ .el-input__inner {
  padding: 20px 20px;
  border: 1px solid #b7b7b7;
  border-radius: 10px;
  color: #000;
}
/deep/ .el-input__inner::placeholder {
  color: #333;
}
#app {
  width: 100%;
}
.el-cascader {
  width: 545px;
}
.senmioa {
  padding: 18px 0;
  width: 403px;
  background: linear-gradient(180deg, #019ffd 0%, #0565fd 100%);
  border-radius: 10px 10px 10px 10px;
  color: #fff;
  font-weight: bold;
  margin: 50px auto;
  cursor: pointer;
  font-size: 18px;
}
.center_line {
  width: 1px;
  height: 462px;
  background: #c5c5c5;
}
.text_right {
  width: 685px;
  background: #fff;
  box-shadow: 0px 3px 14px 1px rgba(0, 0, 0, 0.08);
  border-radius: 23px 23px 23px 23px;
}
.text_right_topback {
  width: 100%;
  height: 64px;
  position: relative;
  top: -25px;
}
.text_right_topback img {
  width: 100%;
  height: 64px;
  position: absolute;
  z-index: 1;
}
.text_right_topback p {
  position: absolute;
  z-index: 8;
  /* background: aqua; */
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #fff;
  margin-left: 20px;
}
.quan_text {
  /* background: aqua; */
  margin-left: 100px;
}
.quan_text > div:nth-child(1) {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 24px;
  font-weight: bold;
}
.quan_text > div:nth-child(1) span {
  width: 10px;
  height: 10px;
  background-color: #0565fd;
  border-radius: 50%;
  margin-right: 14px;
}
.quan_text > div:nth-child(2) {
  color: #0565fd;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  margin-left: 24px;
}
.quan_text > div:nth-child(2) span {
  font-size: 16px;
  color: #666666;
  font-weight: initial;
  margin-left: 34px;
}
.quan_texttwo {
  margin-left: 100px;
}
.quan_texttwo > div:nth-child(1) {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 24px;
  font-weight: bold;
}
.quan_texttwo > div:nth-child(1) span {
  width: 10px;
  height: 10px;
  background-color: #0565fd;
  border-radius: 50%;
  margin-right: 14px;
}
.quan_texttwo {
  margin-top: 38px;
  padding-bottom: 33px;
}
.allimg {
  display: flex;
  margin-top: 20px;
  margin-left: 24px;
}
.wximg {
  width: 194px;
  text-align: center;
  color: #333333;
  font-size: 16px;
  text-align: center;
  margin-right: 32px;
}
.wximg img {
  width: 100%;
  height: 191px;
  display: block;
  margin: 0 auto 16px;
}
.baicarinfor {
  width: 100%;
  background: #f5f9fd;
  padding: 40px 0;
}
.bai_one {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 30px;
  font-weight: bold;
}
.bai_one span {
  color: #0565fd;
}
.bai_one p {
  padding: 0 15px;
  color: #0565fd;
}
.bai_two {
  display: flex;
  justify-content: space-around;
  /* margin-top: 54px; */
  width: 60%;
  margin: 54px auto 0;
}
.alone_bai {
  width: 140px;
  text-align: center;
  color: #000;
  font-size: 20px;
}
.alone_bai p {
  font-size: 30px;
  font-weight: bold;
  margin-top: 16px;
}
.alone_bai span {
  font-size: 20px;
  font-weight: initial;
}
.bai_three {
  display: flex;
  justify-content: space-around;
  padding: 30px 60px;
  background: #fff;
  border-radius: 20px;
  width: 90%;
  margin: 40px auto;
}
.alone_three {
  display: flex;
  align-items: center;
}
.alone_three img {
  width: 68px;
  height: 68px;
}
.alone_three_text {
  flex: 1;
  margin-left: 16px;
}
.alone_three_text > div:nth-child(1) {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
}
.alone_three_text > div:nth-child(2) {
  color: #000000;
  margin-top: 14px;
  font-weight: initial;
  font-size: 17px;
}
</style>