<template>
  <div class="twoalltext">
    <span v-html="datalist.content2"></span>
  </div>
</template>

<script>
import { getAbout } from "../utils/api";
export default {
  data() {
    return {
      datalist: {},
    };
  },
  mounted() {
    this.getAbout();
  },
  methods: {
    getAbout() {
      getAbout().then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          this.datalist = res.data.data;
        }
      });
    },
  },
};
</script>

<style>
.twoalltext {
  width: 100%;
  background: #0565fd;
  font-size: 16px;
  color: #fff;
  padding: 20px 0;
  margin: 0 auto;
}
.twoalltext span {
  width: 90%;
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 12px;
}
</style>